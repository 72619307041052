import React, { useState } from "react";
import { Form, Row, Col, Container, Button, Card, Image } from "react-bootstrap";
import { sendEmail } from "../emailService";  // Importing the email service

import indiaFlag from "../assets/images/india-flag.png";
import uaeFlag from "../assets/images/uae-flag.png";
import usaFlag from "../assets/images/usa-flag.png";
import rwandaFlag from "../assets/images/rwanda-flag.png";
import arrowwithMap from '../assets/images/arrow-with-map.png';
import LayerFour from '../assets/images/layers/Layer_4.png'



const Contact = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        mobile: "",
        company: "",
        message: "",
        role: "",      // New role field
        subscribe: "yes",  // New subscription field
        heardAbout: "",
        jobTitle: "",
        lastName: "",
    });

    const [loading, setLoading] = useState(false);
    const [responseMessage, setResponseMessage] = useState("");

    // Email validation regex (blocks free providers)
    const businessEmailRegex = /@(gmail\.com|yahoo\.com|outlook\.com|hotmail\.com|aol\.com|icloud\.com|protonmail\.com|zoho\.com|mail\.com|gmx\.com)$/i;

    // Handle input changes
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setResponseMessage("");

        const { name, lastName, email, mobile, company, jobTitle, message, role, subscribe, heardAbout } = formData;

        // Validate required fields
        if (!name || !lastName || !email || !mobile || !company || !jobTitle || !message || !role || !heardAbout) {
            setResponseMessage("Please fill in all required fields.");
            setLoading(false);
            return;
        }

        // Validate business email
        if (businessEmailRegex.test(email)) {
            setResponseMessage("Please enter a valid business email address.");
            setLoading(false);
            return;
        }

        // Email content
        const emailHtml = `
           <table role="presentation" style="width:100%; border-collapse: collapse; border: 1px solid #ddd;">
  <tr>
    <td colspan="2" style="background-color:#f2f2f2; padding:10px; font-weight:bold; text-align:center;">
      Contact Us - Enquiry Form
    </td>
  </tr>
  <tr>
    <td style="padding:8px; border:1px solid #ddd;"><strong>First Name:</strong></td>
    <td style="padding:8px; border:1px solid #ddd;">${name}</td>
  </tr>
   <tr>
        <td style="padding:8px; border:1px solid #ddd;"><strong>Last Name:</strong></td>
        <td style="padding:8px; border:1px solid #ddd;">${formData.lastName}</td>
      </tr>
  <tr>
    <td style="padding:8px; border:1px solid #ddd;"><strong>Email:</strong></td>
    <td style="padding:8px; border:1px solid #ddd;">${email}</td>
  </tr>
  <tr>
    <td style="padding:8px; border:1px solid #ddd;"><strong>Phone:</strong></td>
    <td style="padding:8px; border:1px solid #ddd;">${mobile}</td>
  </tr>
  <tr>
    <td style="padding:8px; border:1px solid #ddd;"><strong>Company:</strong></td>
    <td style="padding:8px; border:1px solid #ddd;">${company}</td>
  </tr>
   <tr>
        <td style="padding:8px; border:1px solid #ddd;"><strong>Job Title:</strong></td>
        <td style="padding:8px; border:1px solid #ddd;">${formData.jobTitle}</td>
      </tr>
   <tr>
        <td style="padding:8px; border:1px solid #ddd;"><strong>Heard About Us:</strong></td>
        <td style="padding:8px; border:1px solid #ddd;">${formData.heardAbout || "Not Specified"}</td>
      </tr>
  <tr>
  <td style="padding:8px; border:1px solid #ddd;"><strong>Role:</strong></td>
  <td style="padding:8px; border:1px solid #ddd;">${formData.role}</td>
</tr>
<tr>
        <td style="padding:8px; border:1px solid #ddd;"><strong>Subscription:</strong></td>
        <td style="padding:8px; border:1px solid #ddd;">${formData.subscribe === "yes" ? "Subscribed" : "Not Subscribed"}</td>
      </tr>
  <tr>
    <td style="padding:8px; border:1px solid #ddd;"><strong>Message:</strong></td>
    <td style="padding:8px; border:1px solid #ddd;">${message}</td>
  </tr>
</table>

        `;

        try {
            const response = await sendEmail("sakshid@comsenseconsulting.com", "Contact Us - Enquiry Form", emailHtml);
            if (response.success) {
                setResponseMessage("Form submitted successfully!");

                // Clear the message after 3 seconds
                setTimeout(() => {
                    setResponseMessage("");
                }, 3000);

                // setFormData({ name: "", email: "", mobile: "", company: "", message: "" });
                setFormData({
                    name: "",
                    lastName: "",
                    email: "",
                    mobile: "",
                    company: "",
                    jobTitle: "",
                    message: "",
                    role: "",
                    subscribe: "yes",
                    heardAbout: ""
                });
            } else {
                setResponseMessage("Failed to send email. Please try again.");
            }
        } catch (error) {
            setResponseMessage("An error occurred. Please try again.");
        }

        setLoading(false);
    };

    return (
        <>


            <section className="page-section bg-white pt-80 pb-80">
                <Container>

                    <Row className='align-items-center mb-5'>
                        <Col col={12}>
                            <div class="text-center d-flex align-items-center justify-content-center">
                                <h2 class="section-heading section-heading-center fw-700 fs-1">Let's start your <span className="text-ee0000">Journey</span> towards <span className="d-block text-00A1E0">Fruitful Outcomes</span></h2>
                            </div>
                        </Col>
                    </Row>

                    <Row className="mt-5 align-ite">
                        <Col lg={6}>
                            <div className="pe-5">
                                <Image className="w-100" src={arrowwithMap} />
                                <h5 className="ls-1 fw-700 fs-16 text-252B42  mt-4">Want to Know More about our Services?</h5>
                                <p className="text-252B42 fw-400 fs-12 lh-base mb-2 ls-0">Leave your details here and our team will get in touch with you right away.</p>
                                <h5 className="text-252B42 fw-400 fs-12 lh-base ls-0">Together we will arrange a suitable time to discuss your enquiry in more detail in a no-obligation initial meeting.</h5>
                                <h5 className="ls-1 fw-700 fs-16 text-252B42 mt-5 mb-1">You can also email your enquiry to-</h5>
                                <h5 className="ls-1 fw-700 fs-16"><a className="text-decoration-none text-ee0000 ls-1 fw-700 fs-16" href="mailto:connect@comsenseconsulting.com">connect@comsenseconsulting.com</a></h5>

                            </div>
                            <div>
                                <h4 className="text-252B42 fw-700 fs-16 bordered-text bordered-small bordered-text-start pb-0 red-underline-bottom ls-0 mt-5 mb-mt-4 mb-4">Office Locations</h4>
                                <Row className="d-flex mb-0 mb-lg-4 pe-4 mt-4">
                                    <Col lg={6} md={6} xs={6} className="mb-3 mb-lg-0">
                                        <Image className="mb-1" src={indiaFlag} style={{ width: '70px' }} />
                                        <h5 className="text-252B42 fw-600 mt-2 mt-lg-2 fs-20">INDIA</h5>

                                    </Col>
                                    <Col lg={6} md={6} xs={6} className="mb-3 mb-lg-0">
                                        <Image className="mb-1" src={uaeFlag} style={{ width: '70px' }} />
                                        <h5 className="text-252B42 fw-600 mt-2 mt-lg-2 fs-20">UAE</h5>
                                    </Col>
                                </Row>
                                <Row className="d-flex mb-4 pe-4">
                                    <Col lg={6} md={6} xs={6} className="mb-3 mb-lg-0">
                                        <Image className="mb-1" src={usaFlag} style={{ width: '70px' }} />
                                        <h5 className="text-252B42 fw-600 mt-2 mt-lg-2 fs-20">USA</h5>
                                    </Col>
                                    <Col lg={6} md={6} xs={6} className="mb-3 mb-lg-0">
                                        <Image className="mb-1" src={rwandaFlag} style={{ width: '70px' }} />
                                        <h5 className="text-252B42 fw-600 mt-2 mt-lg-2 fs-20">RWANDA</h5>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <Card className="bg-E2F1FE position-relative overflow-hidden box-shadow-four z-0 border-0 rounded-3 p-0 p-lg-3 p-md-3 p-sm-3 pb-3 pb-lg-3">
                            <Image src={LayerFour} className="position-absolute bottom-0 start-0 z-n1 w-75" />

                                <Card.Body>
                                    <Form onSubmit={handleSubmit}>
                                        <Row>
                                            <Col lg={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>First Name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder=""
                                                        name="name"
                                                        value={formData.name}
                                                        onChange={handleChange}
                                                        className="rounded-1 custom-input custom-input-two"
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Last Name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder=""
                                                        name="lastName"
                                                        value={formData.lastName}
                                                        onChange={handleChange}
                                                        className="rounded-1 custom-input custom-input-two"
                                                    />
                                                </Form.Group>
                                            </Col>

                                            <Col lg={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Company</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder=""
                                                        name="company"
                                                        value={formData.company}
                                                        onChange={handleChange}
                                                        className="rounded-1 custom-input custom-input-two"
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Job Title</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder=""
                                                        name="jobTitle"
                                                        value={formData.jobTitle}
                                                        onChange={handleChange}
                                                        className="rounded-1 custom-input custom-input-two"
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control
                                                        type="email"
                                                        placeholder=""
                                                        name="email"
                                                        value={formData.email}
                                                        onChange={handleChange}
                                                        className="rounded-1 custom-input custom-input-two"
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Phone</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder=""
                                                        name="mobile"
                                                        value={formData.mobile}
                                                        onChange={handleChange}
                                                        className="rounded-1 custom-input custom-input-two"
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>How did you hear about us?</Form.Label>
                                                    <Form.Select
                                                        name="heardAbout"
                                                        value={formData.heardAbout}
                                                        onChange={handleChange}
                                                        className="rounded-1 custom-input custom-input-two"
                                                    >
                                                        <option value="" disabled>Select</option>
                                                        <option value="Social Media">Social Media</option>
                                                        <option value="Friend">Friend</option>
                                                        <option value="Search Engine">Search Engine</option>
                                                        <option value="Advertisement">Advertisement</option>
                                                        <option value="Event">Event</option>
                                                        <option value="Other">Other</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>

                                            <Col lg={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>What is your role?</Form.Label>
                                                    <Form.Select
                                                        name="role"
                                                        value={formData.role || ""}
                                                        onChange={handleChange}
                                                        className="rounded-1 custom-input custom-input-two"
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Developer">Developer</option>
                                                        <option value="Manager">Manager</option>
                                                        <option value="Designer">Designer</option>
                                                        <option value="Marketer">Marketer</option>
                                                        <option value="Other">Other</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>

                                            <Col lg={12}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>How can we help your organization?</Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={3}
                                                        placeholder=""
                                                        name="message"
                                                        value={formData.message}
                                                        onChange={handleChange}
                                                        className="rounded-1 custom-input custom-input-two"
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={12}>
                                                <Form.Group className="mb-3 mt-1">
                                                    <Form.Label className="mb-3">I would like to receive emails from <span className="fw-700">Comsense</span> with exclusive updates on products, services, events, and alerts. I may unsubscribe at any time.</Form.Label>
                                                    <div>
                                                        <Form.Check
                                                            type="radio"
                                                            label="Yes"
                                                            name="subscribe"
                                                            value="yes"
                                                            className="d-block"
                                                            checked={formData.subscribe === "yes"}
                                                            onChange={handleChange}
                                                            inline
                                                        />
                                                        <Form.Check
                                                            type="radio"
                                                            label="No"
                                                            className="d-block"
                                                            name="subscribe"
                                                            value="no"
                                                            checked={formData.subscribe === "no"}
                                                            onChange={handleChange}
                                                            inline
                                                        />
                                                    </div>
                                                </Form.Group>
                                            </Col>

                                            <Col lg={12}>
                                                <Button
                                                    variant=""
                                                    type="submit"
                                                    className="mt-3 fw-600 d-block m-auto btn btn-danger btn-xl"
                                                    disabled={loading}
                                                >
                                                    {loading ? "Submitting..." : "Submit"}
                                                </Button>
                                            </Col>
                                        </Row>
                                        {responseMessage && (
                                            <p className="mt-3 mb-0 fs-12 text-center" style={{ color: loading ? "blue" : "red" }}>
                                                {responseMessage}
                                            </p>
                                        )}
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default Contact;
