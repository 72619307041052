import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import { Row, Col, Container } from 'react-bootstrap';
import footerLogo from '../../assets/images/dark-logo.png';
import wapp from '../../assets/images/whatsapp.png';
import fbook from '../../assets/images/instagram.png';
import lkdin from '../../assets/images/linkedin.png';
import ytube from '../../assets/images/youtube.png';
import SalesforcePartnerBadge from '../../assets/images/Salesforce_Partner_Badge.png'

const Footer = () => {

    return (
        <>

            <section className='pt-50 bg-darkee footer-area pb-0' style={{background: 'linear-gradient(180deg, #067BB1 -58.37%, #04467A 100%)'}}>
                <Container>
                    <Row>
                        <Col lg={4} md={4}>
                           <Image src={footerLogo} className='fLogo mb-4'/>
                           <div className='footer-wrap'>
                           <ul className='d-flex gap-2'>
                            {/* <li><a href=''><Image src={wapp}/></a></li> */}
                            <li><a href='https://www.instagram.com/comsensemktg/#' target='_blank'><Image src={fbook}/></a></li>
                            <li><a href='https://www.linkedin.com/company/comsense-consulting/' target='_blank'><Image src={lkdin}/></a></li>
                            <li><a href='https://www.youtube.com/channel/UCCekynsEDZS7VORzB5KwX9g' target='_blank'><Image src={ytube}/></a></li>
                           </ul>
                           </div>

                        </Col>
                        <Col lg={2}  md={2}>
                          <div className='footer-wrap'>
                           <h5 className='fw-700 ls-0'>Quick Links</h5>
                           <ul>
                            <li><a href='/'>Home</a></li>
                            {/* <li><a href='/'>Resources</a></li> */}
                            <li><a href='/about'>About Us</a></li>
                            <li><a href='/contact'>Contact Us</a></li>
                           </ul>
                           </div>
                        </Col>
                        <Col lg={4}  md={4}>
                          <div className='footer-wrap'>
                           <h5 className='fw-700 ls-0'>Contact</h5>
                           <p><span className='fw-600 ls-0'>Email</span>: connect@comsenseconsulting.com</p>
                           </div>
                        </Col>
                        <Col lg={2}  md={2}>
                          <div className='footer-wrap text-start text-lg-end'>
                           <Image className='footer-salesforce-logo' src={SalesforcePartnerBadge}/>
                           </div>
                        </Col>
                    </Row>
                    <Row className='mt-5 justify-content-center'>
                        <Col lg={7}  md={10}>
                          <div className='copyright mt-3'>
                            <p>Copyright © 2025 comsensetechnologies. All rights reserved.</p>
                          </div>
                        </Col>
                    </Row>
                </Container>
            </section>

        </>
    );
};

export default Footer;

