import React from 'react';
import Form from 'react-bootstrap/Form';

import Image from 'react-bootstrap/Image';
import shape3 from '../assets/images/layers/Layer_1.png'
import shape4 from '../assets/images/shape4.png'
import shape5 from '../assets/images/shape5.png'
import { Row, Col, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';


import clientOne from "../assets/images/clients/image 12.png";
import clientTwo from "../assets/images/clients/image 13.png";
import clientThree from "../assets/images/clients/image 14.png";
import clientFour from "../assets/images/clients/image 15.png";
import clientFive from "../assets/images/clients/image 16.png";
import clientSix from "../assets/images/clients/image 17.png";
import clientSeven from "../assets/images/clients/image 18.png";
import clientEight from "../assets/images/clients/image 19.png";
import clientNine from "../assets/images/clients/image 20.png";
import clientTen from "../assets/images/clients/image 21.png";
import clientEleven from "../assets/images/clients/image 22.png";
import clientTwelve from "../assets/images/clients/image 23.png";
import clientThirteen from "../assets/images/clients/image 24.png";
import clientFourteen from "../assets/images/clients/image 25.png";
import clientFifteen from "../assets/images/clients/image 26.png";
import clientSixteen from "../assets/images/clients/image 27.png";
import clientSeventeen from "../assets/images/clients/image 28.png";
import clientEightteen from "../assets/images/clients/image 29.png";
import CustomerSuccessImage from '../assets/images/Customer-360°-Success-image.png'

import DataCloudExpertsIcon from '../assets/images/icons/dataCloudExpertsIcon.png';
import DataConsultantsIcon from "../assets/images/icons/dataConsultants.png";
import inTheMartechIndustryIcon from '../assets/images/icons/inTheMartechIndustry.png';
import expertsIcon from '../assets/images/icons/experts.png';
import topPartnersIcon from '../assets/images/icons/topPartners.png';

import tataMotorsIcon from '../assets/images/brands/tata-motors.png';
import royalEnfieldIcon from '../assets/images/brands/royal-enfield.png';
import LuluIcon from '../assets/images/brands/lulu.png';
import ambitFinvestIcon from '../assets/images/brands/ambit-finvest.png';
import sotbellaIcon from '../assets/images/brands/sotbella.png';
import theArtOfLivingIcon from '../assets/images/brands/the-art-of-living.png';
import dataAuditAndStrategyIcon from '../assets/images/icons/Data-Audit-and-Strategy.png';
import SegmentationAndActivationIcon from '../assets/images/icons/Segmentation-and-Activation .png';
import DataIngestionAndUnificationIcon from '../assets/images/icons/Data-Ingestion-and-Unification.png';
import CalculatedInsightsIcon from '../assets/images/icons/Calculated-Insights.png';
import CustomerViewIcon from '../assets/images/icons/Customer-View.png';
import GovernanceAndComplianceIcon from '../assets/images/icons/Governance-and-Compliance.png';
import quote from '../assets/images/icons/quote.png';
import DataColudAssessmentWorkshop from '../assets/images/Data-Colud-Assessment-workshop.png';

import layerOne from '../assets/images/layers/GroupLeft.png';
import layerTwo from '../assets/images/layers/GroupRight.png';
import PartnersForMeaningfulImpact from '../assets/images/partner-impact-two.png'
import bibaIcon from '../assets/images/brands/biba.png';
import aisIcon from '../assets/images/brands/ais.png';
import octaveIcon from '../assets/images/brands/octave.png';
import srIcon from '../assets/images/brands/sr.png';
import mudraIcon from '../assets/images/brands/mudra.png';
import IncreaseInQualifiedLeadIcon from '../assets/images/icons/IncreaseInQualifiedLead.png';
import MartechSMEsIcon from '../assets/images/icons/Martech-SMEs.png';
import MarketingCloudStrategyAndRoadmapIcon from '../assets/images/icons/Marketing-Cloud-Strategy-And-Roadmap.png'
import ABTestingIcon from '../assets/images/icons/ABTesting.png';
import MultiChannelJourneyOrchestrationIcon from '../assets/images/icons/Multi-Channel-Journey-Orchestration.png';
import AdvancedSegmentationAndPersonalizationIcon from '../assets/images/icons/AdvancedSegmentationAndPersonalization.png'
import EinsteinGenerativeAIIcon from '../assets/images/icons/EinsteinGenerativeAI.png';
import ReportingAndAnalysisIcon from '../assets/images/icons/ReportingAndAnalysis.png'

import salesforceArrow from '../assets/images/salesforce-arrow.png';
import certifiedOne from '../assets/images/Accredited-Professional-Marketing-Cloud-Intelligence-Badge.png';
import certifiedLogos from '../assets/images/certification-logos-two.png';
import mcCloudBannerMobile from '../assets/images/banner/mc-cloud-banner-human-mobile.png'

import ReductionInManualWorkIcon from '../assets/images/icons/Reduction-in-Manual-Work.png';
import HigherConversionRatesIcon from '../assets/images/icons/Higher-Conversion-Rates.png';
import ReportingOrROIMetricsIcon from '../assets/images/icons/Reporting-ROI-Metrics.png';
import GreaterCustomerRetentionAndLoyaltyIcon from '../assets/images/icons/Greater-Customer-Retention-and-Loyalty.png';
import MoreROIFromMarketingInvestmentsIcon from '../assets/images/icons/More-ROI-from-Marketing-Investments.png';

const clientImages = [
    clientOne,
    clientTwo,
    clientThree,
    clientFour,
    clientFive,
    clientSix,
    clientSeven,
    clientEight,
    clientNine,
    clientTen,
    clientEleven,
    clientTwelve,
    clientThirteen,
    clientFourteen,
    clientFifteen,
    clientSixteen,
    clientSeventeen,
    clientEightteen
];

const MarketingCloud = () => {
    return (
        <>
            <header class="masthead subPageHead data-cloud-header marketingCloudBanner">
                <div class="container">
                    <Row className='justify-content-start'>
                        <Col xl={7} lg={5}>
                            <div class="masthead-heading text-white">Salesforce <span className='fancy-text4 ps-1 pe-2 mb-0  z-0 text-EE0000  position-relative overflow-hiddens text-white'> Marketing</span> <br />Cloud Services</div>
                            <p className='mt-4 mb-0 text-white'>Take your brand to the channels where your customers are!</p>
                        </Col>
                        <Col lg={12} className='mt-5 mt-lg-0 d-block d-lg-none'>
                          <Image className='w-100' src={mcCloudBannerMobile}/>
                        </Col>

                    </Row>
                </div>
            </header>


            <section class="bg-EFFDFF pt-80 pb-80">
                <Container>
                    <Row className='align-items-center mb-3'>
                        <Col col={12}>
                            <div class="text-center d-flex align-items-center justify-content-center">
                                <h2 class="section-heading section-heading-center fw-700 fs-2">Deliver Personalized Customer <span className='bordered-text'>Journeys</span> at Scale</h2>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-4 text-center justify-content-center">
                        <Col lg={11} md={12} className="mb-2 mb-lg-0">
                            <p className='text-252B42 fw-600 ls-0 mt-0 mb-2 fs-20'>Create connected, AI-powered experiences that drive engagement, conversions and loyalty</p>
                            <p className="mt-0 fs-5 fw-normal ls-0">At Comsense, we help brands unlock the full potential of <span className='text-028bc6 fw-600'>Salesforce Marketing Cloud</span> leveraging data, automation, and AI to craft seamless, hyper-personalized customer journeys. </p>
                        </Col>
                    </Row>

                </Container>
            </section>

            <section class="page-section pt-80 pb-80 bg-white">
                <Container>

                    <Row className='align-items-center mt-4 justify-content-center testimonials-area position-relative'>
                        <Col  xl={2} lg={2} md={4} sm={6} xs={12}  className='cus-col-sm text-center'>
                            <div className='mb-5 mb-lg-0 bg-transparent shadow-none'>
                                <div className='position-relative'>
                                    <Image width={70} className='m-auto' src={MartechSMEsIcon} />
                                </div>
                                <h5 className='mb-1 mt-4 fw-800 fs-4'>30+</h5>
                                <p className='fw-500 fs-6'>Martech SME’s</p>
                            </div>
                        </Col>
                        <Col  xl={2} lg={2} md={4} sm={6} xs={12}  className='cus-col-sm text-center'>
                            <div className='mb-5 mb-lg-0 bg-transparent shadow-none'>
                                <div className='position-relative'>
                                    <Image width={70} className='m-auto' src={DataConsultantsIcon} />
                                </div>
                                <h5 className='mb-1 mt-4 fw-800 fs-4'>25+</h5>
                                <p className='fw-500 fs-6'>SFMC Experts</p>
                            </div>
                        </Col>
                        <Col xl={2} lg={2} md={4} sm={6} xs={12}  className='cus-col-sm text-center'>
                            <div className='mb-5 mb-lg-0 bg-transparent shadow-none'>
                                <div className='position-relative'>
                                    <Image width={70} className='m-auto' src={inTheMartechIndustryIcon} />
                                </div>
                                <h5 className='mb-1 mt-4 fw-800 fs-4'>10+ Years</h5>
                                <p className='fw-500 fs-6'>in the Martech Industry</p>
                            </div>
                        </Col>
                        <Col xl={2} lg={2} md={4} sm={6} xs={12}  className='cus-col-sm text-center'>
                            <div className='mb-5 mb-lg-0 bg-transparent shadow-none'>
                                <div className='position-relative'>
                                    <Image width={70} className='m-auto' src={expertsIcon} />
                                </div>
                                <h5 className='mb-1 mt-4 fw-800 fs-4'>Multi Industry</h5>
                                <p className='fw-500 fs-6'>Expertise</p>
                            </div>
                        </Col>
                        <Col xl={2} lg={2} md={4} sm={6} xs={12}  className='cus-col-sm text-center'>
                            <div className='mb-5 mb-lg-0 bg-transparent shadow-none'>
                                <div className='position-relative'>
                                    <Image width={70} className='m-auto' src={topPartnersIcon} />
                                </div>
                                <h5 className='mb-1 mt-4 fw-800 fs-4'>Top Partners</h5>
                                <p className='fw-500 fs-6'>of Salesforce</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section class="page-section bg-E2F1FE pt-70 pb-70">
                <Container>
                    <Row className='align-items-center mb-5'>
                        <Col col={12}>
                            <div class="text-center d-flex align-items-center justify-content-center">
                                <h2 class="section-heading section-heading-center fw-700 fs-2">Some Brands we have led to <span className='bordered-text'>success</span> with Marketing Cloud</h2>
                            </div>
                        </Col>
                    </Row>
                    <Row className="align-items-stretch mt-4 justify-content-center row-cols-xl-5 row-cols-lg-4 row-cols-md-3 row-cols-sm-2 row-cols-2">
    {[
        tataMotorsIcon, royalEnfieldIcon, bibaIcon, mudraIcon, srIcon, 
        octaveIcon, aisIcon
    ].map((icon, index) => (
        <Col key={index} className="d-flex mb-3 mb-lg-0">
            <div className="white-shadow-box text-center flex-fill d-flex flex-column justify-content-center">
                <Image className="m-auto h-100"  style={{objectFit:'contain', maxWidth:'100%'}} width={140} src={icon} />
            </div>
        </Col>
    ))}
</Row>

{/* 
                    <Row className="align-items-stretch mt-4 justify-content-center">
                        <Col xs={6} sm={4} md={4} lg={3} xl={2} className="d-flex mb-3 mb-lg-0">
                            <div className="white-shadow-box text-center flex-fill d-flex flex-column justify-content-center">
                                <Image className='m-auto' width={150} src={tataMotorsIcon} />
                            </div>
                        </Col>
                        <Col xs={6} sm={4} md={4} lg={3} xl={2} className="d-flex mb-3 mb-lg-0">
                            <div className="white-shadow-box text-center flex-fill d-flex flex-column justify-content-center">
                                <Image className='m-auto' width={140} src={royalEnfieldIcon} />
                            </div>
                        </Col>
                        <Col xs={6} sm={4} md={4} lg={3} xl={2} className="d-flex mb-3 mb-lg-0">
                            <div className="white-shadow-box text-center flex-fill d-flex flex-column justify-content-center">
                                <Image className='m-auto' width={140} src={bibaIcon} />
                            </div>
                        </Col>
                        <Col xs={6} sm={4} md={4} lg={3} xl={2} className="d-flex mb-3 mb-lg-0">
                            <div className="white-shadow-box text-center flex-fill d-flex flex-column justify-content-center">
                                <Image className='m-auto' width={140} src={mudraIcon} />
                            </div>
                        </Col>
                        <Col xs={6} sm={4} md={4} lg={3} xl={2} className="d-flex mb-3 mb-lg-0">
                            <div className="white-shadow-box text-center flex-fill d-flex flex-column justify-content-center">
                                <Image className='m-auto' width={140} src={srIcon} />
                            </div>
                        </Col>
                        <Col xs={6} sm={4} md={4} lg={3} xl={2} className="d-flex mb-3 mb-lg-0">
                            <div className="white-shadow-box text-center flex-fill d-flex flex-column justify-content-center">
                                <Image className='m-auto' width={140} src={octaveIcon} />
                            </div>
                        </Col>
                        <Col xs={6} sm={4} md={4} lg={3} xl={2} className="d-flex mb-3 mb-lg-0">
                            <div className="white-shadow-box text-center flex-fill d-flex flex-column justify-content-center">
                                <Image className='m-auto' width={140} src={aisIcon} />
                            </div>
                        </Col>
                    </Row> */}


                    {/* <Row className="align-items-stretch justify-content-center mt-4 row-cols-lg-5 row-cols-1">
                        {[
                            tataMotorsIcon, royalEnfieldIcon, bibaIcon, mudraIcon,
                            srIcon, octaveIcon, aisIcon
                        ].map((icon, index) => (
                            <Col key={index} className="d-flex flex-column h-100 mb-4">
                                <div style={{ height: '90px' }} className="white-shadow-box text-center d-flex flex-column justify-content-center align-items-center flex-grow-1 w-100">
                                    <Image className="m-auto" width={140} src={icon} />
                                </div>
                            </Col>
                        ))}
                    </Row> */}


                </Container>
            </section>

            <section class="page-section pt-80 pb-80 bg-white">
                <Container>
                    <Row className='align-items-center mb-5'>
                        <Col col={11}>
                            <div class="text-center d-flex align-items-center justify-content-center">
                                <h2 class="section-heading section-heading-center fw-700 fs-2">How We Help You Win with <span className='bordered-text'>Marketing</span> Cloud ?</h2>
                            </div>
                        </Col>
                    </Row>
                    <Row className='align-items-center mt-4 justify-content-start position-relative'>
                        <Col lg={12}>
                            <Row>
                                <Col lg={6} md={6}>
                                    <div className='mb-4 mb-lg-4 d-flex align-items-center'>
                                        <div>
                                            <div className='blue-gradient-sqr-icon text-center position-relative'>
                                                <Image width={30} className='m-auto' src={MarketingCloudStrategyAndRoadmapIcon} />
                                            </div>
                                        </div>
                                        <div className='content ms-4 pe-3'>
                                            <h5 className='mb-2 fw-600 fs-4 ls-minus-05'>Marketing Cloud Strategy & Roadmap</h5>
                                            <p className='fw-500 fs-6 mb-0 ls-minus-02 text-2C354CB2'>Aligning SFMC with your business goals.</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6}  md={6}>
                                    <div className='mb-4 mb-lg-4  d-flex align-items-center'>
                                        <div>
                                            <div className='blue-gradient-sqr-icon text-center position-relative'>
                                                <Image width={30} className='m-auto' src={ABTestingIcon} />
                                            </div>
                                        </div>
                                        <div className='content ms-4 pe-3'>
                                            <h5 className='mb-2 fw-600 fs-4 ls-minus-05'>A/B Testing</h5>
                                            <p className='fw-500 fs-6 mb-0 ls-minus-02 text-2C354CB2'>Test different content variations to determine what
                                                resonates best with your audience for improved results. </p>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6}  md={6}>
                                    <div className='mb-4 mb-lg-4  d-flex align-items-center'>
                                        <div>
                                            <div className='blue-gradient-sqr-icon text-center position-relative'>
                                                <Image width={30} className='m-auto' src={MultiChannelJourneyOrchestrationIcon} />
                                            </div>
                                        </div>
                                        <div className='content ms-4 pe-3'>
                                            <h5 className='mb-2 fw-600 fs-4 ls-minus-05'>Multi-Channel Journey Orchestration </h5>
                                            <p className='fw-500 fs-6 mb-0 ls-minus-02 text-2C354CB2'>Design unified customer journeys driven by lifecycle strategies for a cohesive experience. </p>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6} md={6}>
                                    <div className='mb-4 mb-lg-4  d-flex align-items-center'>
                                        <div>
                                            <div className='blue-gradient-sqr-icon text-center position-relative'>
                                                <Image width={30} className='m-auto' src={AdvancedSegmentationAndPersonalizationIcon} />
                                            </div>
                                        </div>
                                        <div className='content ms-4 pe-3'>
                                            <h5 className='mb-2 fw-600 fs-4 ls-minus-05'>Advanced Segmentation & Personalization</h5>
                                            <p className='fw-500 fs-6 mb-0 ls-minus-02 text-2C354CB2'>Tailor messages to the right audience at the right time for maximum relevance. </p>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6}  md={6}>
                                    <div className='mb-4 mb-lg-4  d-flex align-items-center'>
                                        <div>
                                            <div className='blue-gradient-sqr-icon text-center position-relative'>
                                                <Image width={30} className='m-auto' src={EinsteinGenerativeAIIcon} />
                                            </div>
                                        </div>
                                        <div className='content ms-4 pe-3'>
                                            <h5 className='mb-2 fw-600 fs-4 ls-minus-05'>Einstein Generative AI </h5>
                                            <p className='fw-500 fs-6 mb-0 ls-minus-02 text-2C354CB2'>Leverage AI to enhance efficiency and maximize impact in your campaigns. </p>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6}  md={6}>
                                    <div className='mb-4 mb-lg-4  d-flex align-items-center'>
                                        <div>
                                            <div className='blue-gradient-sqr-icon text-center position-relative'>
                                                <Image width={30} className='m-auto' src={ReportingAndAnalysisIcon} />
                                            </div>
                                        </div>
                                        <div className='content ms-4 pe-3'>
                                            <h5 className='mb-2 fw-600 fs-4 ls-minus-05'>Reporting & Analysis </h5>
                                            <p className='fw-500 fs-6 mb-0 ls-minus-02 text-2C354CB2'>Accurately track and analyse campaign performance to ensure actionable insights. </p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section class="page-section bg-E2F1FE pt-80 pb-80">
                <Container>
                    <Row className='align-items-center'>
                        <Col lg={12}>
                            <div class="text-center d-flex align-items-center justify-content-start">
                                <h2 class="section-heading section-heading-center fw-700 fs-2"><span className='bordered-text bordered-text-start'>Partners</span> for meaningful impact</h2>
                            </div>
                            
                        </Col>
                        <Col lg={12} className='mt-2'>
                        
                        <div className='white-card box-shadow-five bg-white rounded-1 mt-4'>
                             <Row className='align-items-center'>
                                <Col lg={6}>
                             <div className='d-flex mb-5 align-items-center'>
                             <div className='me-3'><Image src={salesforceArrow} width={8}/></div>
                             <div>
                                <p className='ls-0 fw-700 mb-0 text-252B42 fs-18'>Use Case Library & Frameworks for Faster Time to Market</p>
                             </div>
                             </div>
                             <div className='d-flex mb-5 align-items-center'>
                             <div className='me-3'><Image src={salesforceArrow} width={8}/></div>
                             <div>
                                <p className='ls-0 fw-700 mb-0 text-252B42 fs-18'>Successfully Executed 100+ Campaigns across Industries</p>
                             </div>
                             </div>
                             <div className='d-flex align-items-center'>
                             <div className='me-3'><Image src={salesforceArrow} width={8}/></div>
                             <div>
                                <p className='ls-0 fw-700 mb-0 text-252B42 fs-18'>10+ years in Automation & Martech Domain</p>
                             </div>
                             </div>
                             </Col>
                             <Col lg={6} className='mt-5 mt-lg-0'>
                              <Image src={certifiedLogos} className='w-100'/>
                             </Col>
                             </Row>
                         </div>
                        </Col>
                    </Row>

                </Container>
            </section>



            <section class="page-section bg-EFFDFF pt-70 pb-70">
                <Container>
                    <Row className='align-items-center mb-5'>
                        <Col lg={10} className='offset-lg-1'>
                            <div class="text-center">
                                <span className='d-block fw-500 ls-0 mb-1 fs-15 text-252B42'>Every engagement follows a simple formula</span>
                                <h2 class="section-heading text-center mb-0 section-heading-center fw-700 fs-40">We listen. We plan. We execute. </h2>
                                <p className='text-252B42 ls-0 fw-500 fs-20'>Whichever engagement you choose we align with your goals, craft a clear roadmap, and get it done—while guiding you strategically every step of the way</p>
                            </div>
                        </Col>
                    </Row>

                    <Row className="align-items-stretch mt-4 justify-content-center">
                        <Col className="mb-3 d-flex" lg={3}  md={6}>
                            <div className="box-back rounded-1 z-0 w-100 d-flex flex-column">
                                <div className="w8-bx-shadow z-1 bg-white w8-box position-relative rounded-1 ps-3 pe-3 pt-3 pb-4 d-flex flex-column flex-grow-1">
                                    <h3 className="Fw-700 fs-16 fw-bold ls-0 mt-1">Strategy & Consultation</h3>
                                    <p className="text-left mb-0 ls-0 fw-400 fs-11 flex-grow-1">
                                        We take the time to understand your business, aligning your goals with the right data, tools, and people to drive meaningful results.
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col className="mb-3 d-flex" lg={3}  md={6}>
                            <div className="blue-box-back rounded-1  z-0 w-100 d-flex flex-column">
                                <div className="w8-bx-shadow z-1 bg-blue-gradient w8-box position-relative rounded-1 ps-3 pe-3 pt-3 pb-4 d-flex flex-column flex-grow-1">
                                    <h3 className="Fw-700 fs-16 fw-bold ls-0 mt-1 text-white">Marketing Cloud Implementation</h3>
                                    <p className="text-left mb-0 ls-0 text-white fw-400 fs-11 flex-grow-1">
                                        Ensuring a smooth, high-impact deployment with seamless integrations.
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col className="mb-3 d-flex" lg={3} md={6}>
                            <div className="box-back rounded-1 z-0 w-100 d-flex flex-column">
                                <div className="w8-bx-shadow z-1 bg-white w8-box position-relative rounded-1 ps-3 pe-3 pt-3 pb-4 d-flex flex-column flex-grow-1">
                                    <h3 className="Fw-700 fs-16 fw-bold ls-0 mt-1">Managed Services</h3>
                                    <p className="text-left mb-0 ls-0 fw-400 fs-11 flex-grow-1">
                                        Get extra hands to keep things moving forward without increasing your staff size. We'll run it daily, staying proactive and delivering strategic insights                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col className="mb-3 d-flex" lg={3} md={6}>
                            <div className="blue-box-back rounded-1  z-0 w-100 d-flex flex-column">
                                <div className="w8-bx-shadow z-1 bg-blue-gradient w8-box position-relative rounded-1 ps-3 pe-3 pt-3 pb-4 d-flex flex-column flex-grow-1">
                                    <h3 className="Fw-700 fs-16 fw-bold ls-0 mt-1 text-white">Audit</h3>
                                    <p className="text-left mb-0 ls-0 text-white fw-400 fs-11 flex-grow-1">
                                        We will understand your as is situation, conduct a current state analysis, identify gaps and discover quick wins to go further.                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </section>

            <section class="page-section pt-80 pb-80 bg-white">
                <Container>
                    <Row className='align-items-center mb-0'>
                        <Col col={11}>
                            <div class="text-center d-flex align-items-center justify-content-center">
                                <h2 class="section-heading section-heading-center fw-700 fs-2"><span className='fancy-text2 ps-2 mb-3 pe-3 z-0  position-relative overflow-hiddens text-white'>Impact</span> You Could Realize with Marketing Cloud + Comsense</h2>
                            </div>
                        </Col>
                    </Row>
                    <Row className='align-items-center mt-3 justify-content-center position-relative'>
                        <Col lg={12}>
                            <Row className='justify-content-center'>
                                <Col lg={5}  md={6}>
                                    <div className='mb-4 mb-lg-4 d-flex align-items-center'>
                                        <div>
                                            <div style={{ width: '50px', height: '50px', lineHeight: '45px' }} className='box-shadow-two text-center position-relative rounded-0'>
                                                <Image width={30} className='m-auto' src={IncreaseInQualifiedLeadIcon} />
                                            </div>
                                        </div>
                                        <div className='content ms-4 pe-3'>
                                            <h5 className='mb-2 fw-500 fs-5 ls-minus-05'>Increase in Qualified Lead</h5>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={5} md={6}>
                                    <div className='mb-4 mb-lg-4 d-flex align-items-center'>
                                        <div>
                                            <div style={{ width: '50px', height: '50px', lineHeight: '45px' }} className='box-shadow-two text-center position-relative rounded-0'>
                                                <Image width={30} className='m-auto' src={ReductionInManualWorkIcon} />
                                            </div>
                                        </div>
                                        <div className='content ms-4 pe-3'>
                                            <h5 className='mb-2 fw-500 fs-5 ls-minus-05'>Reduction in Manual Work</h5>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={5} md={6}>
                                    <div className='mb-4 mb-lg-4 d-flex align-items-center'>
                                        <div>
                                            <div style={{ width: '50px', height: '50px', lineHeight: '45px' }} className='box-shadow-two text-center position-relative rounded-0'>
                                                <Image width={30} className='m-auto' src={HigherConversionRatesIcon} />
                                            </div>
                                        </div>
                                        <div className='content ms-4 pe-3'>
                                            <h5 className='mb-2 fw-500 fs-5 ls-minus-05'>Higher Conversion Rates</h5>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={5} md={6}>
                                    <div className='mb-4 mb-lg-4 d-flex align-items-center'>
                                        <div>
                                            <div style={{ width: '50px', height: '50px', lineHeight: '45px' }} className='box-shadow-two text-center position-relative rounded-0'>
                                                <Image width={30} className='m-auto' src={ReportingOrROIMetricsIcon} />
                                            </div>
                                        </div>
                                        <div className='content ms-4 pe-3'>
                                            <h5 className='mb-2 fw-500 fs-5 ls-minus-05'>Reporting / ROI Metrics</h5>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={5} md={6}>
                                    <div className='mb-4 mb-lg-4 d-flex align-items-center'>
                                        <div>
                                            <div style={{ width: '50px', height: '50px', lineHeight: '45px' }} className='box-shadow-two text-center position-relative rounded-0'>
                                                <Image width={30} className='m-auto' src={GreaterCustomerRetentionAndLoyaltyIcon} />
                                            </div>
                                        </div>
                                        <div className='content ms-4 pe-3'>
                                            <h5 className='mb-2 fw-500 fs-5 ls-minus-05'>Greater Customer Retention & Loyalty</h5>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={5} md={6}>
                                    <div className='mb-4 mb-lg-4 d-flex align-items-center'>
                                        <div>
                                            <div style={{ width: '50px', height: '50px', lineHeight: '45px' }} className='box-shadow-two text-center position-relative rounded-0'>
                                                <Image width={30} className='m-auto' src={MoreROIFromMarketingInvestmentsIcon} />
                                            </div>
                                        </div>
                                        <div className='content ms-4 pe-3'>
                                            <h5 className='mb-2 fw-500 fs-5 ls-minus-05'>More ROI from Marketing Investments</h5>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>



            <section class="page-section z-0 bg-E2F1FE overflow-hidden pt-70 pb-70 position-relative">
                <Image className='position-absolute z-n1 w-250 bg-cover h-auto position-absolute start-0 bottom-0' src={layerOne} />
                <Container>
                    <Row className='align-items-center justify-content-center'>
                        <Col lg={7}>
                            <div class="text-center">
                                <h2 class="text-252B42 fw-700 fs-26" style={{ lineHeight: 'normal' }}>Start Your Marketing Cloud Journey Today</h2>
                                <p className='text-252B42 fw-600 ls-minus036 mt-4 mb-2 fs-4'>Get a SFMC Audit / Consultation</p>
                                <p className='ls-minus036 fs-13'>Assess your current marketing strategy, uncover optimization opportunities & deploy use cases for personalised experiences across channels.</p>
                                <Nav.Link className="d-inline-block  text-white btn btn-danger btn-xl me-3 ls-0 fw-600" as={Link} to="../contact">Talk to our Expert</Nav.Link>
                                </div>
                        </Col>
                    </Row>
                </Container>
                <Image className='position-absolute w-250 z-n1 bg-cover h-auto position-absolute end-0 top-0 opacity-25' src={layerTwo} />
            </section>



        </>


    );
};

export default MarketingCloud;
