import React from 'react';
import Form from 'react-bootstrap/Form';

import Image from 'react-bootstrap/Image';
import shape3 from '../assets/images/layers/Layer_1.png'
import shape4 from '../assets/images/shape4.png'
import shape5 from '../assets/images/shape5.png'
import { Row, Col, Container } from 'react-bootstrap';

import clientOne from "../assets/images/clients/image 12.png";
import clientTwo from "../assets/images/clients/image 13.png";
import clientThree from "../assets/images/clients/image 14.png";
import clientFour from "../assets/images/clients/image 15.png";
import clientFive from "../assets/images/clients/image 16.png";
import clientSix from "../assets/images/clients/image 17.png";
import clientSeven from "../assets/images/clients/image 18.png";
import clientEight from "../assets/images/clients/image 19.png";
import clientNine from "../assets/images/clients/image 20.png";
import clientTen from "../assets/images/clients/image 21.png";
import clientEleven from "../assets/images/clients/image 22.png";
import clientTwelve from "../assets/images/clients/image 23.png";
import clientThirteen from "../assets/images/clients/image 24.png";
import clientFourteen from "../assets/images/clients/image 25.png";
import clientFifteen from "../assets/images/clients/image 26.png";
import clientSixteen from "../assets/images/clients/image 27.png";
import clientSeventeen from "../assets/images/clients/image 28.png";
import clientEightteen from "../assets/images/clients/image 29.png";
import CustomerSuccessImage from '../assets/images/Customer-360°-Success-image.png'

import DataCloudExpertsIcon from '../assets/images/icons/dataCloudExpertsIcon.png';
import DataConsultantsIcon from "../assets/images/icons/dataConsultants.png";
import inTheMartechIndustryIcon from '../assets/images/icons/inTheMartechIndustry.png';
import expertsIcon from '../assets/images/icons/experts.png';
import topPartnersIcon from '../assets/images/icons/topPartners.png';


import bibaIcon from '../assets/images/brands/biba.png';
import lodhaIcon from '../assets/images/brands/lodha.png';


import tataMotorsIcon from '../assets/images/brands/tata-motors.png';
import royalEnfieldIcon from '../assets/images/brands/royal-enfield.png';
import LuluIcon from '../assets/images/brands/lulu.png';
import ambitFinvestIcon from '../assets/images/brands/ambit-finvest.png';
import sotbellaIcon from '../assets/images/brands/sotbella.png';
import theArtOfLivingIcon from '../assets/images/brands/the-art-of-living.png';
import dataAuditAndStrategyIcon from '../assets/images/icons/Data-Audit-and-Strategy.png';
import SegmentationAndActivationIcon from '../assets/images/icons/Segmentation-and-Activation .png';
import DataIngestionAndUnificationIcon from '../assets/images/icons/Data-Ingestion-and-Unification.png';
import CalculatedInsightsIcon from '../assets/images/icons/Calculated-Insights.png';
import CustomerViewIcon from '../assets/images/icons/Customer-View.png';
import GovernanceAndComplianceIcon from '../assets/images/icons/Governance-and-Compliance.png';
import quote from '../assets/images/icons/quote.png';
import DataColudAssessmentWorkshop from '../assets/images/Data-Colud-Assessment-workshop.png';

import layerOne from '../assets/images/layers/GroupLeft.png';
import layerTwo from '../assets/images/layers/GroupRight.png';
import PartnersForMeaningfulImpact from '../assets/images/Partners-for-meaningful-impact.png';
import MartechSMEsIcon from '../assets/images/icons/Martech-SMEs.png';
import AudiencesAndAffinityProfilingIcon from '../assets/images/icons/AudiencesAndAffinityProfiling.png';
import ActivityVisitorAndResultsReportsIcon from '../assets/images/icons/ActivityVisitorAndResultsReports.png';
import WebsiteAndAppPersonalizationIcon from '../assets/images/icons/WebsiteAndAppPersonalization.png';
import AITestAndLearnIcon from '../assets/images/icons/AITestAndLearn.png';
import CatalogObjectDataIcon from '../assets/images/icons/CatalogObjectData.png';

import salesforceArrow from '../assets/images/salesforce-arrow.png';
import certifiedOne from '../assets/images/marketing-cloud-personalization-logo.png';

import mcCloudPersonalizationBannerMobile from '../assets/images/banner/mcCloudPersonalizationBannerMobile.png'

const clientImages = [
    clientOne,
    clientTwo,
    clientThree,
    clientFour,
    clientFive,
    clientSix,
    clientSeven,
    clientEight,
    clientNine,
    clientTen,
    clientEleven,
    clientTwelve,
    clientThirteen,
    clientFourteen,
    clientFifteen,
    clientSixteen,
    clientSeventeen,
    clientEightteen
];

const MarketingCloudPersonalization = () => {
    return (
        <>
            <header class="masthead subPageHead data-cloud-header markeing-cloud-pesonalization-banner mCloudPersonalizationBanner">
                <div class="container">
                    <Row className='justify-content-start'>
                        <Col xl={12} lg={5}>
                            <div class="masthead-heading text-white">Salesforce  <span className='fancy-text4 ps-1 pe-2 mb-0  z-0 text-EE0000  position-relative overflow-hiddens text-white'>Marketing </span><br /> Cloud Personalization</div>
                            <p className='mt-4 mb-0 text-white'>Interact With Your Customers Wherever They Are in Real Time</p>
                        </Col>
                        <Col lg={12} className='mt-5 mt-lg-0 d-block d-lg-none'>
                          <Image className='w-100' src={mcCloudPersonalizationBannerMobile}/>
                        </Col>
                    </Row>
                </div>
            </header>


            <section class="bg-EFFDFF pt-80 pb-80">
                <Container>
                    <Row className='align-items-center mb-3'>
                        <Col col={12}>
                            <div class="text-center d-flex align-items-center justify-content-center">
                                <h2 class="section-heading section-heading-center fw-700 fs-2">Transform every customer interaction into a dynamic, personalized <span className='bordered-text'>experience</span></h2>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-4 text-center">
                        <Col lg={11} md={12} className="d-flex mb-2 mb-lg-0">
                            <p className="mt-0 fs-5 fw-normal ls-0">Personalization is everything when it comes to connecting with your audience and building lasting relationships. We’re here to guide you through every part of the process—from laying down the strategy to implementation. At Comsense, we help brands leverage <span className='fw-600'>Salesforce Marketing Cloud Personalization</span> to create real-time, AI-powered customer experiences that drive engagement, conversions, and loyalty.</p>
                        </Col>
                    </Row>

                </Container>
            </section>


            <section class="page-section pt-80 pb-80 bg-white">
                <Container>
                    <Row className='align-items-center mt-0 justify-content-center testimonials-area position-relative'>
                        <Col  xl={2} lg={2} md={4} sm={6} xs={12}  className='cus-col-sm text-center'>
                            <div className='mb-5 mb-lg-0 bg-transparent shadow-none'>
                                <div className='position-relative'>
                                    <Image width={70} className='m-auto' src={MartechSMEsIcon} />
                                </div>
                                <h5 className='mb-1 mt-4 fw-800 fs-4'>30+</h5>
                                <p className='fw-500 fs-6'>Martech SME’s</p>
                            </div>
                        </Col>
                        <Col  xl={2} lg={2} md={4} sm={6} xs={12}  className='cus-col-sm text-center'>
                            <div className='mb-5 mb-lg-0 bg-transparent shadow-none'>
                                <div className='position-relative'>
                                    <Image width={70} className='m-auto' src={DataConsultantsIcon} />
                                </div>
                                <h5 className='mb-1 mt-4 fw-800 fs-4'>25+</h5>
                                <p className='fw-500 fs-6'>SFMC Experts</p>
                            </div>
                        </Col>
                        <Col  xl={2} lg={2} md={4} sm={6} xs={12}  className='cus-col-sm text-center'>
                            <div className='mb-5 mb-lg-0 bg-transparent shadow-none'>
                                <div className='position-relative'>
                                    <Image width={70} className='m-auto' src={inTheMartechIndustryIcon} />
                                </div>
                                <h5 className='mb-1 mt-4 fw-800 fs-4'>10+ Years</h5>
                                <p className='fw-500 fs-6'>in the Martech Industry</p>
                            </div>
                        </Col>
                        <Col  xl={2} lg={2} md={4} sm={6} xs={12}  className='cus-col-sm text-center'>
                            <div className='mb-5 mb-lg-0 bg-transparent shadow-none'>
                                <div className='position-relative'>
                                    <Image width={70} className='m-auto' src={expertsIcon} />
                                </div>
                                <h5 className='mb-1 mt-4 fw-800 fs-4'>Multi Industry</h5>
                                <p className='fw-500 fs-6'>Expertise</p>
                            </div>
                        </Col>
                        <Col  xl={2} lg={2} md={4} sm={6} xs={12}  className='cus-col-sm text-center'>
                            <div className='mb-5 mb-lg-0 bg-transparent shadow-none'>
                                <div className='position-relative'>
                                    <Image width={70} className='m-auto' src={topPartnersIcon} />
                                </div>
                                <h5 className='mb-1 mt-4 fw-800 fs-4'>Top Partners</h5>
                                <p className='fw-500 fs-6'>of Salesforce</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section class="page-section bg-E2F1FE pt-70 pb-70">
                <Container>
                    <Row className='align-items-center mb-5'>
                        <Col col={12}>
                            <div class="text-center d-flex align-items-center justify-content-center">
                                <h2 class="section-heading lh-base section-heading-center fw-700 fs-2">Some Brands we have led to <span className='bordered-text bordered-text-minus'>success</span> with Marketing Cloud Personalization </h2>
                            </div>
                        </Col>
                    </Row>

                    {/* <Row className="align-items-stretch mt-4">
                        <Col className="d-flex mb-3 mb-lg-0">
                            <div className="white-shadow-box text-center flex-fill d-flex flex-column justify-content-center">
                                <Image className='m-auto' width={150} src={tataMotorsIcon} />
                            </div>
                        </Col>
                        <Col className="d-flex mb-3 mb-lg-0">
                            <div className="white-shadow-box text-center flex-fill d-flex flex-column justify-content-center">
                                <Image className='m-auto' width={140} src={royalEnfieldIcon} />
                            </div>
                        </Col>
                        <Col className="d-flex mb-3 mb-lg-0">
                            <div className="white-shadow-box text-center flex-fill d-flex flex-column justify-content-center">
                                <Image className='m-auto' width={140} src={LuluIcon} />
                            </div>
                        </Col>
                        <Col className="d-flex mb-3 mb-lg-0">
                            <div className="white-shadow-box text-center flex-fill d-flex flex-column justify-content-center">
                                <Image className='m-auto' width={140} src={ambitFinvestIcon} />
                            </div>
                        </Col>
                        <Col className="d-flex mb-3 mb-lg-0">
                            <div className="white-shadow-box text-center flex-fill d-flex flex-column justify-content-center">
                                <Image className='m-auto' width={140} src={sotbellaIcon} />
                            </div>
                        </Col>
                        <Col className="d-flex mb-3 mb-lg-0">
                            <div className="white-shadow-box text-center flex-fill d-flex flex-column justify-content-center">
                                <Image className='m-auto' width={140} src={theArtOfLivingIcon} />
                            </div>
                        </Col>
                    </Row> */}


<Row className="align-items-stretch mt-4 justify-content-center">
                        <Col xs={6} sm={4} md={4} lg={3} xl={2} className="d-flex mb-3 mb-lg-0">
                            <div className="white-shadow-box text-center flex-fill d-flex flex-column justify-content-center">
                                <Image className='m-auto h-100' style={{objectFit:'contain', maxWidth:'100%'}} width={150} src={tataMotorsIcon} />
                            </div>
                        </Col>
                        <Col xs={6} sm={4} md={4} lg={3} xl={2} className="d-flex mb-3 mb-lg-0">
                            <div className="white-shadow-box text-center flex-fill d-flex flex-column justify-content-center">
                                <Image className='m-auto h-100' style={{objectFit:'contain', maxWidth:'100%'}} width={140} src={royalEnfieldIcon} />
                            </div>
                        </Col>
                        <Col xs={6} sm={4} md={4} lg={3} xl={2} className="d-flex mb-3 mb-lg-0">
                            <div className="white-shadow-box text-center flex-fill d-flex flex-column justify-content-center">
                                <Image className='m-auto h-100' style={{objectFit:'contain', maxWidth:'100%'}} width={140} src={LuluIcon} />
                            </div>
                        </Col>
                        <Col xs={6} sm={4} md={4} lg={3} xl={2} className="d-flex mb-3 mb-lg-0">
                            <div className="white-shadow-box text-center flex-fill d-flex flex-column justify-content-center">
                                <Image className='m-auto h-100' style={{objectFit:'contain', maxWidth:'100%'}} width={140} src={sotbellaIcon} />
                            </div>
                        </Col>
                        <Col xs={6} sm={4} md={4} lg={3} xl={2} className="d-flex mb-3 mb-lg-0">
                            <div className="white-shadow-box text-center flex-fill d-flex flex-column justify-content-center">
                                <Image className='m-auto h-100' style={{objectFit:'contain', maxWidth:'100%'}} width={140} src={theArtOfLivingIcon} />
                            </div>
                        </Col>
                    </Row>




{/* <Row className="align-items-stretch mt-4 justify-content-center">
{[
        tataMotorsIcon, royalEnfieldIcon, LuluIcon, sotbellaIcon, 
        theArtOfLivingIcon
    ].map((icon, index) => (
                        <Col xs={6} sm={4} md={4} lg={3} xl={2} className="d-flex mb-3 mb-lg-0">
                            <div className="white-shadow-box text-center flex-fill d-flex flex-column justify-content-center">
                                <Image className='m-auto' width={150} src={icon} />
                            </div>
                        </Col>
    ))}
   </Row> */}
{/* 
                    <Row className="align-items-stretch justify-content-center mt-0 row-cols-lg-5 row-cols-1">
    {[
        tataMotorsIcon, royalEnfieldIcon, LuluIcon, sotbellaIcon, 
        theArtOfLivingIcon
    ].map((icon, index) => (
        <Col key={index} className="d-flex flex-column h-100 mb-4">
        <div style={{height:'90px', width:'140px'}} className="white-shadow-box text-center d-flex flex-column justify-content-center align-items-center flex-grow-1 w-100">
            <Image className="m-auto h-100" width={140} style={{objectFit:'contain'}} src={icon} />
        </div>
    </Col>
    ))}
</Row> */}


                </Container>
            </section>
            <section class="page-section pt-80 pb-80 bg-white">
                <Container>
                    <Row className='align-items-center mb-5'>
                        <Col col={11}>
                            <div class="text-center d-flex align-items-center justify-content-center">
                                <h2 class="section-heading section-heading-center fw-700 fs-2">How We Help You Win with Marketing Cloud <span className='bordered-text'>Personalisation</span></h2>
                            </div>
                        </Col>
                    </Row>
                    <Row className='align-items-center mt-4 justify-content-start position-relative'>
                       <Col lg={11}>
                       <Row>
                        <Col lg={6} md={6}>
                            <div className='mb-4 mb-lg-4 d-flex align-items-center'>
                                <div>
                                    <div className='blue-gradient-sqr-icon text-center position-relative'>
                                        <Image width={30} className='m-auto' src={AudiencesAndAffinityProfilingIcon} />
                                    </div>
                                </div>
                                <div className='content ms-4 pe-3'>
                                    <h5 className='mb-2 fw-600 fs-4 ls-minus-05'>Audiences & Affinity Profiling </h5>
                                    <p className='fw-500 fs-6 mb-0 ls-minus-02 text-2C354CB2'>Engage the right people at the right time. </p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} md={6}>
                            <div className='mb-4 mb-lg-4  d-flex align-items-center'>
                                <div>
                                    <div className='blue-gradient-sqr-icon text-center position-relative'>
                                        <Image width={30} className='m-auto' src={ActivityVisitorAndResultsReportsIcon} />
                                    </div>
                                </div>
                                <div className='content ms-4 pe-3'>
                                    <h5 className='mb-2 fw-600 fs-4 ls-minus-05'>Activity, Visitor & Results Reports </h5>
                                    <p className='fw-500 fs-6 mb-0 ls-minus-02 text-2C354CB2'>Evaluate campaign efficiency. </p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} md={6}>
                            <div className='mb-4 mb-lg-4  d-flex align-items-center'>
                                <div>
                                    <div className='blue-gradient-sqr-icon text-center position-relative'>
                                        <Image width={30} className='m-auto' src={WebsiteAndAppPersonalizationIcon} />
                                    </div>
                                </div>
                                <div className='content ms-4 pe-3'>
                                    <h5 className='mb-2 fw-600 fs-4 ls-minus-05'>Website & App Personalization</h5>
                                    <p className='fw-500 fs-6 mb-0 ls-minus-02 text-2C354CB2'>AI-driven decisions to determine the most relevant next engagement for your customers.</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} md={6}>
                            <div className='mb-4 mb-lg-4  d-flex align-items-center'>
                                <div>
                                    <div className='blue-gradient-sqr-icon text-center position-relative'>
                                        <Image width={30} className='m-auto' src={AITestAndLearnIcon} />
                                    </div>
                                </div>
                                <div className='content ms-4 pe-3'>
                                    <h5 className='mb-2 fw-600 fs-4 ls-minus-05'>AI Test and Learn</h5>
                                    <p className='fw-500 fs-6 mb-0 ls-minus-02 text-2C354CB2'>Optimise your web and app experience with A/B and multivariate testing </p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} md={6}>
                            <div className='mb-4 mb-lg-4  d-flex align-items-center'>
                                <div>
                                    <div className='blue-gradient-sqr-icon text-center position-relative'>
                                        <Image width={30} className='m-auto' src={CatalogObjectDataIcon} />
                                    </div>
                                </div>
                                <div className='content ms-4 pe-3'>
                                    <h5 className='mb-2 fw-600 fs-4 ls-minus-05'>Catalog Object Data </h5>
                                    <p className='fw-500 fs-6 mb-0 ls-minus-02 text-2C354CB2'>Customize Personalization to fit your business.</p>
                                </div>
                            </div>
                        </Col>
                        </Row>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section class="page-section bg-E2F1FE pt-80 pb-80">
                <Container>
                    <Row className='align-items-center'>
                        <Col lg={12}>
                            <div class="text-center d-flex align-items-center justify-content-start">
                                <h2 class="section-heading section-heading-center fw-700 fs-2"><span className='bordered-text bordered-text-start'>Partners</span> for meaningful impact</h2>
                            </div>
                        </Col>
                        <Col lg={5} className='mt-2'>
                        <div className='white-card box-shadow-five bg-white rounded-1 mt-4'>
                                <div className='d-flex mb-4 align-items-center'>
                                    <div className='me-3'><Image src={salesforceArrow} width={8} /></div>
                                    <div>
                                        <Image src={certifiedOne} width={90} />
                                    </div>
                                </div>
                                <div className='d-flex mb-4 align-items-center'>
                                    <div className='me-3'><Image src={salesforceArrow} width={8} /></div>
                                    <div>
                                        <p className='ls-0 fw-700 text-252B42 fs-18 mb-0'>30+ Certified Experts</p>
                                    </div>
                                </div>
                                <div className='d-flex mb-4 align-items-center'>
                                    <div className='me-3'><Image src={salesforceArrow} width={8} /></div>
                                    <div>
                                        <p className='ls-0 fw-700 text-252B42 mb-0 fs-18'>Top Salesforce Partners</p>
                                    </div>
                                </div>
                                <div className='d-flex mb-4 align-items-center'>
                                    <div className='me-3'><Image src={salesforceArrow} width={8} /></div>
                                    <div>
                                        <p className='ls-0 fw-700 text-252B42 mb-0 fs-18'>10+ years in the Martech Domain</p>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <div className='me-3'><Image src={salesforceArrow} width={8} /></div>
                                    <div>
                                        <p className='ls-0 fw-700 text-252B42 mb-0 fs-18'>Implemented Personalization across Industries</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </section>

            <section class="page-section bg-EFFDFF pt-70 pb-70">
                <Container>
                    <Row className='align-items-center mb-5'>
                        <Col lg={10} className='offset-lg-1'>
                            <div class="text-center">
                                <span className='d-block fw-500 ls-0 mb-1 fs-15 text-252B42'>Every engagement follows a simple formula</span>
                                <h2 class="section-heading text-center mb-0 section-heading-center fw-700 fs-40">We listen. We plan. We execute. </h2>
                                <p className='text-252B42 ls-0 fw-500 fs-20'>Whichever engagement you choose we align with your goals, craft a clear roadmap, and get it done—while guiding you strategically every step of the way</p>
                            </div>
                        </Col>
                    </Row>

                    <Row className="align-items-stretch mt-4">
                        <Col className="mb-3 d-flex" lg={3}  md={6}>
                            <div className="box-back rounded-1 z-0 w-100 d-flex flex-column">
                                <div className="w8-bx-shadow z-1 bg-white w8-box position-relative rounded-1 ps-3 pe-3 pt-3 pb-4 d-flex flex-column flex-grow-1">
                                    <h3 className="Fw-700 fs-16 fw-bold ls-0 mt-1">Strategy & Consultation</h3>
                                    <p className="text-left mb-0 ls-0 fw-400 fs-11 flex-grow-1">
                                    We take the time to understand your business, aligning your goals with the right data, tools, and people to drive meaningful results. 
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col className="mb-3 d-flex" lg={3} md={6}>
                            <div className="blue-box-back rounded-1  z-0 w-100 d-flex flex-column">
                                <div className="w8-bx-shadow z-1 bg-blue-gradient w8-box position-relative rounded-1 ps-3 pe-3 pt-3 pb-4 d-flex flex-column flex-grow-1">
                                    <h3 className="Fw-700 fs-16 fw-bold ls-0 mt-1 text-white">Personalization Cloud Implementation</h3>
                                    <p className="text-left mb-0 ls-0 text-white fw-400 fs-11 flex-grow-1">
                                    Ensuring a smooth, high-impact deployment with seamless integrations.
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col className="mb-3 d-flex" lg={3} md={6}>
                            <div className="box-back rounded-1 z-0 w-100 d-flex flex-column">
                                <div className="w8-bx-shadow z-1 bg-white w8-box position-relative rounded-1 ps-3 pe-3 pt-3 pb-4 d-flex flex-column flex-grow-1">
                                    <h3 className="Fw-700 fs-16 fw-bold ls-0 mt-1">Managed Services</h3>
                                    <p className="text-left mb-0 ls-0 fw-400 fs-11 flex-grow-1">
                                    Get extra hands to keep things moving forward without increasing your staff size. We'll run it daily, staying proactive and delivering strategic insights.
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col className="mb-3 d-flex" lg={3} md={6}>
                            <div className="blue-box-back rounded-1 z-0 w-100 d-flex flex-column">
                                <div className="w8-bx-shadow z-1 bg-blue-gradient w8-box position-relative rounded-1 ps-3 pe-3 pt-3 pb-4 d-flex flex-column flex-grow-1">
                                    <h3 className="Fw-700 fs-16 fw-bold text-white ls-0 mt-1">Account Audit</h3>
                                    <p className="text-left mb-0 ls-0 fw-400 text-white fs-11 flex-grow-1">
                                    We will understand your as is situation, conduct a current state analysis, identify gaps and discover quick wins to go further.
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </section>

            <section class="page-section bg-white pt-80 pb-80">
                <Container>
                    <Row className='align-items-center'>
                        <Col lg={12}>

                            <div className='bg-E2F1FE box-shadow-one rounded-4'>
                                <Row className='align-items-center'>
                                    <Col lg={7} md={7}>
                                        <div className="p-23">
                                        <span className='fancy-text2 ps-2 d-block mb-3 pe-3 z-0  position-relative overflow-hiddens fw-800 text-white fs-14'>Customer Success Story</span>
                                            <p className='mb-2 pb-0 ps-6 fs-6 mt-4'>Driving Higher Conversions</p>
                                            <h4 className='ls-minus036 mb-2 fs-18 fw-700 lh-base text-252B42'>How Marketing Cloud Personalization Reduced Dropouts & Minimized Website Abandonment for India’s top vehicle market leader</h4>
                                            <a class="btn btn-danger btn-xl me-3 ls-0 fw-600" href="#">Download the full story here</a>
                                        </div>
                                    </Col>
                                    <Col lg={5} md={5} className='pe-3 pe-lg-0'>
                                        <div className=''>
                                            <Image src={DataColudAssessmentWorkshop} className='w-100' />
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                        </Col>
                    </Row>



                </Container>
            </section>

            <section class="page-section z-0 bg-E2F1FE overflow-hidden pt-70 pb-70 position-relative">
                <Image className='position-absolute z-n1 w-250 bg-cover h-auto position-absolute start-0 bottom-0' src={layerOne}/>
                <Container>
                    <Row className='align-items-center justify-content-center'>
                        <Col lg={12}>
                            <div class="text-center">
                                <h2 class="text-252B42 fw-700 fs-26" style={{lineHeight:'normal'}}>Let’s Elevate Your Customer Experience on Web & App </h2>
                                <a class="btn btn-danger btn-xl me-3 mt-4 ls-0 fw-600" href="#">Talk to our Expert</a>
                            </div>
                        </Col>
                    </Row>
                    </Container>
                    <Image className='position-absolute z-n1 w-250 bg-cover h-auto position-absolute end-0 top-0 opacity-25' src={layerTwo}/>
                    </section>



        </>


    );
};

export default MarketingCloudPersonalization;
