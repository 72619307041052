import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import DataCloud from './pages/dataCloud';
import MarketingCloud from './pages/marketingCloud'
import About from './pages/about'
import Contact from './pages/contact';
import MarketingCloudPersonalization from './pages/marketingCloudPersonalization';
import AgentForce from './pages/agentForce';

// import Services from './pages/Services';
// import Solutions from './pages/Solutions';
// import AboutUs from './pages/AboutUs';
// import ContactUs from './pages/ContactUs';
import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './pages/common/header';
import Footer from './pages/common/footer'

import './App.css';
const App = () => {
  return (
<>      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/DataCloud" element={<DataCloud />} />
        <Route path="/MarketingCloud" element={<MarketingCloud />} />
        <Route path="/MarketingCloudPersonalization" element={<MarketingCloudPersonalization />} />
        <Route path="/agentForce" element={<AgentForce />} />
        <Route path="/About" element={<About />} />
        <Route path="/Contact" element={<Contact />} />
      </Routes>
      <Footer/>
      </>

   
  );
};

export default App;
