import axios from "axios";

// ⚠️ Do NOT expose API keys in frontend; move this to a secure backend.
const SMTP2GO_API_KEY = "api-EBD25B96AF474D6AB011E941E39FB36C"; 

export const sendEmail = async (to, subject, message) => {
  try {
    const response = await axios.post(
      "https://api.smtp2go.com/v3/email/send",
      {
        api_key: SMTP2GO_API_KEY,
        sender: "no-reply@kleanmail.com", // Must be verified in SMTP2GO
        to: [to], // Ensure 'to' is an array
        subject: subject,
        html_body: message,
      }
    );

    console.log("SMTP Response:", response.data);

    if (response.data.data.succeeded > 0) {
      return { success: true, message: "Email sent successfully!" };
    } else {
      return {
        success: false,
        message: response.data.data.failed.length > 0
          ? response.data.data.failed[0]?.error || "Unknown error"
          : "Email sending failed, but no failures were listed.",
      };
    }
  } catch (error) {
    return { success: false, message: error.response?.data?.error || error.message };
  }
};
