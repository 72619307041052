import React from 'react';

import Image from 'react-bootstrap/Image';
import { Row, Col, Container } from 'react-bootstrap';
import icon7 from '../assets/images/ic7.png';
import icon8 from '../assets/images/ic8.png';
import icon9 from '../assets/images/ic9.png';
import icon10 from '../assets/images/ic10.png';
import ManWithGreenSweaterLooksThoughtful from '../assets/images/Man-with-green-sweater looks-thoughtful.png'
import award from '../assets/images/award.png';
import awardTwo from '../assets/images/award-two.png';
import LayerTwo from '../assets/images/layers/Layer_2.png';
import LayerThree from '../assets/images/layers/Layer_3.png';
import BridgingTheGAPIcon from '../assets/images/icons/BridgingTheGAP.png';
import ProvenSkillCOEFrameworkIcon from '../assets/images/icons/Proven-skill-COE-framework.png';
import AcceleratorsIcon from '../assets/images/icons/Accelerators-for-faster-time.png';
import LocalCompanyGlobalMindsetIcon from '../assets/images/icons/local-company-global-mindset.png';
import { FaLinkedin } from "react-icons/fa";
import sagarBabar from '../assets/images/Management/sagar-babar.png';
import BijuNambiar from '../assets/images/Management/biju-nambiar.png';
import AniketNeurgaonkar from '../assets/images/Management/AniketNeurgaonkar.png';
import shushilGujrathi from '../assets/images/Management/shushil-Gujrathi.png';
import VineetLadhania from '../assets/images/Management/Vineet-Ladhania.png'

const About = () => {
    return (
        <>
            <header class="masthead subPageHead data-cloud-header about-banner">
                <div class="container">
                    <Row className='justify-content-start'>
                        <Col lg={6}>
                            <p className='mb-2 text-white fw-600'>About us</p>
                            <h1 class="mb-0 masthead-heading fw-700 text-white fs-34">Bringing Excellence To Every Day At Workplace</h1>
                        </Col>
                    </Row>
                </div>
            </header>

            <section class="bg-white pt-80 pb-80">
                <Container>
                    <Row className='align-items-center'>
                        <Col lg={5}>
                            <p className='fw-400 fs-6 mb-4 ls-minus-02 text-252B42 ls-minus-064'>When we started Comsense in 2015, our mission was clear—help businesses turn data into action. Too many companies were sitting on valuable customer insights but struggling to make them work.</p>
                            <p className='fw-400 fs-6 mb-4 ls-minus-02 text-252B42 ls-minus-064'>As ex-IBMers with deep expertise in marketing, data, and technology, we built Comsense into a global Martech and Data consulting powerhouse. Today, we specialize in Salesforce solutions, helping brands maximize their CRM, personalize customer experiences, and drive real revenue impact.​</p>
                            <p className='fw-400 fs-6 mb-4 ls-minus-02 text-252B42 ls-minus-064'>Fast forward to today, and we’ve grown into a global Martech and Data consulting company. ​We specialize in Salesforce helping brands unlock the full potential of their CRM, personalize customer experiences, and drive real revenue impact. ​</p>
                            <p className='fw-400 fs-6 mb-0 ls-minus-02 text-252B42 ls-minus-064'>As a Salesforce Preferred Partner, we go beyond implementation—we ensure businesses unlock the full potential of Salesforce Data Cloud, Marketing Cloud, and beyond. Our strength lies in bridging the gap between data and action, turning insights into measurable results. If you're looking to scale marketing, optimize customer journeys, and maximize your Salesforce investment, we’re here to make it happen.</p>
                        </Col>
                        <Col lg={7} className='mt-4 mt-lg-0'>
                            <Image className='w-100' src={ManWithGreenSweaterLooksThoughtful} />
                        </Col>
                    </Row>
                </Container>
            </section>


            <section class="page-section pt-80 pb-80 bg-E2F1FE">


                <Container>
                    <Row className="justify-content-center">
                        <Col lg={12}>
                            <Row className='mb-4'>
                                <Col lg={12} className='mb-3'>
                                    <span className='fw-600 ls-minus036 fancy-text3 ps-2 d-block mb-0 pe-3 z-0 text-EE0000  position-relative overflow-hiddens text-white fs-20' style={{ color: '#EE0000 !important' }}>Comsense in Numbers​</span>
                                </Col>
                            </Row>
                            {/* <Row className="align-items-center mt-0 justify-content-between testimonials-area position-relative">
                                <Col className="col-auto text-left">
                                    <div className="mb-5 mb-lg-0 bg-transparent shadow-none">
                                        <h5 className="text-252B42 mb-3 fw-700 fs-7">100+</h5>
                                        <p className="text-252B42 fw-400 pt-3 fs-6 HelveticaRegular width-fit pe-4 border-1 border-067BB1 border-dark border-top">
                                            Man Years Experience
                                        </p>
                                    </div>
                                </Col>
                                <Col className="col-auto text-left">
                                    <div className="mb-5 mb-lg-0 bg-transparent shadow-none">
                                        <h5 className="text-252B42 mb-3 fw-700 fs-7">4</h5>
                                        <p className="text-252B42 fw-400 pt-3 fs-6 HelveticaRegular width-fit pe-4 border-1 border-252B42 border-dark border-top" style={{ borderTop: '1px solid #067BB1' }}>
                                            Global Locations
                                        </p>
                                    </div>
                                </Col>
                                <Col className="col-auto text-left">
                                    <div className="mb-5 mb-lg-0 bg-transparent shadow-none">
                                        <h5 className="text-252B42 mb-3 fw-700 fs-7">45+</h5>
                                        <p className="text-252B42 fw-400 pt-3 fs-6 HelveticaRegular width-fit pe-4 border-1 border-252B42 border-dark border-top">
                                            Global Clients
                                        </p>
                                    </div>
                                </Col>
                                <Col className="col-auto text-left">
                                    <div className="mb-5 mb-lg-0 bg-transparent shadow-none">
                                        <h5 className="text-252B42 mb-3 fw-700 fs-7">Power of 3​</h5>
                                        <p className="text-252B42 fw-400 pt-3 fs-6 HelveticaRegular width-fit pe-4 border-1 border-252B42 border-dark border-top">
                                            Capabilities | Products | Managed Ops
                                        </p>
                                    </div>
                                </Col>
                                <Col className="col-auto text-left">
                                    <div className="mb-5 mb-lg-0 bg-transparent shadow-none">
                                        <h5 className="text-252B42 mb-3 fw-700 fs-7">100+</h5>
                                        <p className="text-252B42 fw-400 pt-3 fs-6 HelveticaRegular width-fit pe-4 border-1 border-067BB1 border-dark border-top">
                                            Team Strength
                                        </p>
                                    </div>
                                </Col>
                            </Row>

                            <Row className="align-items-center mt-3 justify-content-between testimonials-area position-relative">
                                <Col className="col-auto text-left">
                                    <div className="mb-5 mb-lg-0 bg-transparent shadow-none">
                                        <h5 className="text-252B42 mb-3 fw-700 fs-7">45+</h5>
                                        <p className="text-252B42 fw-400 pt-3 fs-6 HelveticaRegular width-fit pe-4 border-1 border-067BB1 border-dark border-top">
                                            Accelerators/​Innovations
                                        </p>
                                    </div>
                                </Col>
                                <Col className="col-auto text-left">
                                    <div className="mb-5 mb-lg-0 bg-transparent shadow-none">
                                        <h5 className="text-252B42 mb-3 fw-700 fs-7">120+​</h5>
                                        <p className="text-252B42 fw-400 pt-3 fs-6 HelveticaRegular width-fit pe-4 border-1 border-252B42 border-dark border-top" style={{ borderTop: '1px solid #067BB1' }}>
                                            Predictive Models
                                        </p>
                                    </div>
                                </Col>
                                <Col className="col-auto text-left">
                                    <div className="mb-5 mb-lg-0 bg-transparent shadow-none">
                                        <h5 className="text-252B42 mb-3 fw-700 fs-7">200+​</h5>
                                        <p className="text-252B42 fw-400 pt-3 fs-6 width-fit pe-4 HelveticaRegular border-1 border-252B42 border-dark border-top">
                                            Dashboards Created
                                        </p>
                                    </div>
                                </Col>
                                <Col className="col-auto text-left">
                                    <div className="mb-5 mb-lg-0 bg-transparent shadow-none">
                                        <h5 className="text-252B42 mb-3 fw-700 fs-7">130 bn+​</h5>
                                        <p className="text-252B42 fw-400 pt-3 fs-6 HelveticaRegular width-fit pe-4 border-1 border-252B42 border-dark border-top">
                                            Customer Records
                                        </p>
                                    </div>
                                </Col>
                                <Col className="col-auto text-left">
                                    <div className="mb-5 mb-lg-0 bg-transparent shadow-none">
                                        <h5 className="text-252B42 mb-3 fw-700 fs-7">4.1/5.0​​</h5>
                                        <p className="text-252B42 fw-400 pt-3 fs-6 HelveticaRegular width-fit pe-4 border-1 border-252B42 border-dark border-top">
                                            CSAT Index
                                        </p>
                                    </div>
                                </Col>
                            </Row> */}
                        </Col>
                    </Row>


                    {/* <Row className="align-items-center mt-0 justify-content-between testimonials-area position-relative">
  {[
    { title: '100+', text: 'Man Years Experience' },
    { title: '4', text: 'Global Locations' },
    { title: '45+', text: 'Global Clients' },
    { title: 'Power of 3', text: 'Capabilities | Products | Managed Ops' },
    { title: '100+', text: 'Team Strength' },
  ].map((item, index) => (
    <Col key={index} xs={6} md={4} lg={2} className="text-left">
      <div className="mb-5 bg-transparent shadow-none">
        <h5 className="text-252B42 mb-3 fw-700 fs-7">{item.title}</h5>
        <p style={{ width: 'fit-content' }} className="text-252B42 fw-400 pt-3 fs-6 HelveticaRegular pe-4 border-1 border-067BB1 border-dark border-top">
          {item.text}
        </p>
      </div>
    </Col>
  ))}
</Row> */}



                    {/* <Row className="align-items-center mt-3 justify-content-between testimonials-area position-relative">
  {[
    { title: '45+', text: 'Accelerators/​Innovations' },
    { title: '120+', text: 'Predictive Models' },
    { title: '200+', text: 'Dashboards Created' },
    { title: '130 bn+', text: 'Customer Records' },
    { title: '4.1/5.0', text: 'CSAT Index' },
  ].map((item, index) => (
    <Col key={index} className="text-left">
      <div className="mb-5 bg-transparent shadow-none">
        <h5 className="text-252B42 mb-3 fw-700 fs-7">{item.title}</h5>
        <p style={{width:'fit-content'}} className="text-252B42 fw-400 pt-3 fs-6 HelveticaRegular pe-4 border-1 border-067BB1 border-dark border-top">
          {item.text}
        </p>
      </div>
    </Col>
  ))}
</Row> */}

                    <Row className="align-items-center mt-0 justify-content-start testimonials-area position-relative">
                        {[
                            { title: '100+', text: 'Man Years Experience', lgSize: 3 },
                            { title: '4', text: 'Global Locations', lgSize: 2 },
                            { title: '45+', text: 'Global Clients', lgSize: 2 },
                            { title: 'Power of 3', text: 'Capabilities | Products | Managed Ops', lgSize: 3 },
                            { title: '100+', text: 'Team Strength', lgSize: 2 },
                        ].map((item, index) => (
                            <Col
                                key={index}
                                xs={12}
                                sm={6}
                                md={4}
                                lg={item.lgSize || 2} // Use lgSize if available, otherwise default to 2
                                className="text-left"
                            >
                                <div className="mb-5 bg-transparent shadow-none">
                                    <h5 className="text-252B42 mb-3 fw-700 fs-7">{item.title}</h5>
                                    <p style={{ width: 'fit-content' }} className="text-252B42 fw-400 pt-3 fs-6 HelveticaRegular pe-4 border-1 border-067BB1 border-dark border-top">
                                        {item.text}
                                    </p>
                                </div>
                            </Col>
                        ))}
                    </Row>


                    {/* <Row className="align-items-center mt-3 justify-content-between testimonials-area position-relative">
  {[
    { title: '45+', text: 'Accelerators/​Innovations' },
    { title: '120+', text: 'Predictive Models' },
    { title: '200+', text: 'Dashboards Created' },
    { title: '130 bn+', text: 'Customer Records' },
    { title: '4.1/5.0', text: 'CSAT Index' },
  ].map((item, index) => (
    <Col key={index} xs={6} md={4} lg={2} className="text-left">
      <div className="mb-5 bg-transparent shadow-none">
        <h5 className="text-252B42 mb-3 fw-700 fs-7">{item.title}</h5>
        <p style={{ width: 'fit-content' }} className="text-252B42 fw-400 pt-3 fs-6 HelveticaRegular pe-4 border-1 border-067BB1 border-dark border-top">
          {item.text}
        </p>
      </div>
    </Col>
  ))}
</Row> */}


                    <Row className="align-items-center mt-3 justify-content-start testimonials-area position-relative">
                        {[
                            { title: '45+', text: 'Accelerators/​Innovations', lgSize: 3 },
                            { title: '120+', text: 'Predictive Models', lgSize: 2 },
                            { title: '200+', text: 'Dashboards Created', lgSize: 2 },
                            { title: '130 bn+', text: 'Customer Records', lgSize: 3 },
                            { title: '4.1/5.0', text: 'CSAT Index', lgSize: 2 },
                        ].map((item, index) => (
                            <Col
                                key={index}
                                xs={12}
                                sm={6}
                                md={4}
                                lg={item.lgSize || 2} // Use lgSize if available, otherwise default to 2
                                className="text-left"
                            >
                                <div className="mb-5 bg-transparent shadow-none">
                                    <h5 className="text-252B42 mb-3 fw-700 fs-7">{item.title}</h5>
                                    <p style={{ width: 'fit-content' }} className="text-252B42 fw-400 pt-3 fs-6 HelveticaRegular pe-4 border-1 border-067BB1 border-dark border-top">
                                        {item.text}
                                    </p>
                                </div>
                            </Col>
                        ))}
                    </Row>

                </Container>

            </section>

            <section class="page-section pt-80 pb-80 bg-white">
                <Container>
                    <Row className='align-items-center mb-5'>
                        <Col col={12}>
                            <div class="text-center d-flex align-items-center justify-content-center">
                                <h2 class="section-heading section-heading-center fw-700 fs-2">Our Achievements</h2>
                            </div>
                        </Col>
                    </Row>
                    <Row className='align-items-center justify-content-center position-relative'>
                        <Col className='' lg={8}>
                            <Row className='align-items-center'>
                                <Col lg={4} md={4}>
                                    <div className="award-box mb-4 mb-lg-0 text-center">
                                        <Image className="w-100" src={award} />
                                        <div className="award-text">
                                            <h6 className="fw-600 fs-12 ls-minus-032 mb-2">Top 3 Salesforce Partner 2024</h6>
                                            <p className="mb-0 fw-400 fs-9 text-uppercase">Salesforce 2024</p>
                                        </div>
                                    </div>
                                </Col>

                                <Col lg={4} md={4}>
                                    <div className="award-box mb-4 mb-lg-0 text-center">
                                        <Image className="w-100" src={award} />
                                        <div className="award-text">
                                            <h6 className="fw-600 fs-12 ls-minus-032 mb-2">Company of the Year</h6>
                                            <p className="mb-0 fw-400 fs-9 text-uppercase">Business Leaders Awards​ 2021​</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={4} md={4}>
                                    <div className="award-box mb-4 mb-lg-0 text-center">
                                        <Image className="w-100" src={award} />
                                        <div className="award-text">
                                            <h6 className="fw-600 fs-12 ls-minus-032 mb-2">10 Indian Startup leading AI Race ​</h6>
                                            <p className="mb-0 fw-400 fs-9 text-uppercase">Analytics India​ 2019​</p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={3} md={3} className='mt-4 mt-lg-0'>
                            <div className='right-award-box text-center'>
                                <Image width={110} className='m-auto' src={awardTwo} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>


            <section class="page-section bg-blue-gradient-two position-relative z-0 pt-80 pb-80">
                <Image src={LayerThree} className="position-absolute top-0 start-0 w-40 z-n1" />
                <Container>
                    <Row className='align-items-center mb-5 align-items-center justify-center' style={{ justifyContent: 'center', textAlign: 'center' }}>
                        <Col lg={8}>
                            <div class="text-center d-flex align-items-center justify-content-center position-relative">
                                <h2 style={{ textShadow: '0px 4px 15px 0px #00000040;' }} class="section-heading section-heading-center fw-700 fs-2 text-white">Our Unique Proposition: Winning Strategy</h2>

                            </div>
                        </Col>
                    </Row>

                    <Row className='align-items-stretch mt-4 position-relative'>
                        <Col lg={3} md={6} className="d-flex">
                            <div className='mb-4 mb-lg-0 bg-E2F1FE flip-card box-shadow-three rounded-4 text-center w-100'>
                                <div className='front-content'>
                                    <Image width={45} src={BridgingTheGAPIcon} />
                                    <h6 className='mt-2 text-capitalize ls-minus-032 fw-800 fs-14 mb-0'>Bridging the gap</h6>
                                </div>
                                <div className='content back-content'>
                                    <p className='mb-0 fs-12 text-white fw-800 ls-minus-032'>
                                        We bring the domain knowledge, business management, and technological expertise to
                                        ensure successful implementation.
                                    </p>
                                </div>
                            </div>
                        </Col>

                        <Col lg={3} md={6} className="d-flex">
                            <div className='mb-4 mb-lg-0  bg-E2F1FE flip-card box-shadow-three rounded-4 text-center w-100'>
                                <div className='front-content'>
                                    <Image width={45} src={ProvenSkillCOEFrameworkIcon} />
                                    <h6 className='mt-2 text-capitalize ls-minus-032 fw-800 fs-14 mb-0'>Proven skill COE framework</h6>
                                </div>
                                <div className='content back-content'>
                                    <p className='mb-0 fs-12 text-white fw-800 ls-minus-032'>
                                        Our proven framework delivers high-quality trained resources within a shorter timeframe
                                        with industry-led certifications.
                                    </p>
                                </div>
                            </div>
                        </Col>

                        <Col lg={3} md={6} className="d-flex">
                            <div className='mb-4 mb-lg-0  bg-E2F1FE flip-card box-shadow-three rounded-4 text-center w-100'>
                                <div className='front-content'>
                                    <Image width={45} src={AcceleratorsIcon} />
                                    <h6 className='mt-2 text-capitalize ls-minus-032 fw-800 fs-14 mb-0'>Accelerators for faster time to market</h6>
                                </div>
                                <div className='content back-content'>
                                    <p className='mb-0 fs-12 text-white fw-800 ls-minus-032'>
                                        We have in-house developed accelerators that can help fast-track implementations, data migration, and analytics for faster time to market
                                    </p>
                                </div>
                            </div>
                        </Col>


                        <Col lg={3} md={6} className="d-flex">
                            <div className='mb-4 mb-lg-0  bg-E2F1FE flip-card box-shadow-three rounded-4 text-center w-100'>
                                <div className='front-content'>
                                    <Image width={45} src={LocalCompanyGlobalMindsetIcon} />
                                    <h6 className='mt-2 text-capitalize ls-minus-032 fw-800 fs-14 mb-0'>Local company; Global mindset</h6>
                                </div>
                                <div className='content back-content'>
                                    <p className='mb-0 fs-12 text-white fw-800 ls-minus-032'>
                                        A high-quality, ethical & knowledge-driven group of individuals with enthusiasm for understanding clients' businesses.
                                    </p>
                                </div>
                            </div>
                        </Col>

                    </Row>

                </Container>
                <Image src={LayerTwo} className="position-absolute bottom-0 z-n1 end-0 w-40" />

            </section>

            <section class="page-section pt-80 pb-80 bg-white">
                <Container>
                    <Row className='align-items-center mb-5'>
                        <Col col={12}>
                            <div class="text-center d-flex align-items-center justify-content-center">
                                <h2 class="section-heading section-heading-center fw-700 fs-2">Leadership</h2>
                            </div>
                        </Col>
                    </Row>
                    <Row className='align-items-center justify-content-center position-relative'>
                        <Col lg={9}>
                            <Row className='justify-content-center position-relative'>
                                <Col className='mb-3 mb-sm-0' lg={4} md={4} sm={6}>
                                    <div class="team-boxarea  ps-4 pe-4 mb-4 mb-lg-0">
                                        <div class="img1">
                                            <Image src={sagarBabar} className='w-100' />
                                        </div>

                                        <div class="content owner-content mt-3 d-flex justify-content-between align-items-top">
                                            <div>
                                                <h6 className='text-252B42 ls-minus-045 fw-800 fs-20 mb-1'>Sagar Babar</h6>
                                                <p className='text-252B42 fw-500 ls-minus-045 fs-13 mb-0'>Co-founder & CEO</p>
                                            </div>
                                            <ul className='list-unstyled'>
                                                <li className='li'><a href="https://www.linkedin.com/in/sagarbabar" target='_blank' style={{ color: '#0077B7' }} className='text-0077B7'><FaLinkedin className='fs-24' /></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                                <Col className='mb-3 mb-sm-0' lg={4} md={4} sm={6}>
                                    <div class="team-boxarea ps-4 pe-4  mb-4 mb-lg-0">
                                        <div class="img1">
                                            <Image src={BijuNambiar} className='w-100' />
                                        </div>

                                        <div class="content owner-content mt-3 d-flex justify-content-between align-items-top">
                                            <div>
                                                <h6 className='text-252B42 ls-minus-045 fw-800 fs-20 mb-1'>Biju Nambiar</h6>
                                                <p className='text-252B42 fw-500 ls-minus-045 fs-13 mb-0'>Co-founder & Sales Director</p>
                                            </div>
                                            <ul className='list-unstyled'>
                                                <li className='li'><a href="https://in.linkedin.com/in/biju-nambiar-6148523" target='_blank' style={{ color: '#0077B7' }} className='text-0077B7'><FaLinkedin className='fs-24' /></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                                <Col className='mb-3 mb-sm-0' lg={4} md={4} sm={6}>
                                    <div class="team-boxarea  ps-4 pe-4  mb-4 mb-lg-0">
                                        <div class="img1">
                                            <Image src={AniketNeurgaonkar} className='w-100' />
                                        </div>

                                        <div class="content owner-content mt-3 d-flex justify-content-between align-items-top">
                                            <div>
                                                <h6 className='text-252B42 ls-minus-045 fw-800 fs-20 mb-1'>Aniket Neurgaonkar</h6>
                                                <p className='text-252B42 fw-500 ls-minus-045 fs-13 mb-0'>Co-founder & Marketing Director</p>
                                            </div>
                                            <ul className='list-unstyled'>
                                                <li className='li'><a href="https://www.linkedin.com/in/aniket-n-0001b34/" target='_blank' style={{ color: '#0077B7' }} className='text-0077B7'><FaLinkedin className='fs-24' /></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </Col>

                            </Row>
                            <Row className='mt-0 mt-lg-5 justify-content-center position-relative'>

                                <Col className='mb-3 mb-sm-0' lg={4} md={4} sm={6}>
                                    <div class="team-boxarea  ps-4 pe-4  mb-4 mb-lg-0">
                                        <div class="img1">
                                            <Image src={VineetLadhania} className='w-100' />
                                        </div>

                                        <div class="content owner-content mt-3 d-flex justify-content-between align-items-top">
                                            <div>
                                                <h6 className='text-252B42 ls-minus-045 fw-800 fs-20 mb-1'>Vineet Ladhania</h6>
                                                <p className='text-252B42 fw-500 ls-minus-045 fs-13 mb-0'>Co-founder & COO</p>
                                            </div>
                                            <ul className='list-unstyled'>
                                                <li className='li'><a href="https://www.linkedin.com/in/vineetl/" target='_blank' style={{ color: '#0077B7' }} className='text-0077B7'><FaLinkedin className='fs-24' /></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                                <Col className='mb-3 mb-sm-0' lg={4} md={4} sm={6}>
                                    <div class="team-boxarea  ps-4 pe-4  mb-4 mb-lg-0">
                                        <div class="img1">
                                            <Image src={shushilGujrathi} className='w-100' />
                                        </div>

                                        <div class="content owner-content mt-3 d-flex justify-content-between align-items-top">
                                            <div>
                                                <h6 className='text-252B42 ls-minus-045 fw-800 fs-20 mb-1'>Shushil Gujrathi</h6>
                                                <p className='text-252B42 fw-500 ls-minus-045 fs-13 mb-0'>Co-founder & Chief Growth Officer</p>
                                            </div>
                                            <ul className='list-unstyled'>
                                                <li className='li'><a href="https://in.linkedin.com/in/sushilgujrathi" target='_blank' style={{ color: '#0077B7' }} className='text-0077B7'><FaLinkedin className='fs-24' /></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>


        </>


    );
};

export default About;
